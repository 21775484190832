import styled from 'styled-components';
import { DemoVehicleLabelStyled } from '../DemoVehicleLabelStyled';
import { rem } from 'polished';

export const DemoVehicleLabelStyledOV = styled(DemoVehicleLabelStyled)`
    .info {
        background: ${({ theme }) => theme.colors.black2};
        color: ${({ theme }) => theme.colors.white};
        font-size: ${({ theme }) => theme.fontSizes.h5};

        svg {
            width: ${rem(19)};
            height: ${rem(19)};
            margin-top: ${rem(-3)};

            g {
                circle,
                line {
                    stroke: ${({ theme }) => theme.colors.white};
                }
            }
        }
    }

    .details {
        color: ${({ theme }) => theme.colors.grey22};
        font-size: ${({ theme }) => theme.fontSizes.h5};
    }
`;
