import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { invisibleScrollbars } from 'src/styles/main';
import { breakpoints } from 'src/styles/theme';
import { isBrandAC, isBrandAP, isBrandDS, isBrandOV, isMarketGB } from 'src/constants/main';
import {
    CategoryTitleStylesAC,
    RowCellGearboxStylesAC,
    RowCellMainStylesAC,
    RowTitleStylesAC,
} from './AC/ModalEngineCompare.AC';
import {
    CategoryTitleStylesAP,
    RowCellGearboxStylesAP,
    RowCellMainStylesAP,
    RowTitleStylesAP,
} from './AP/ModalEngineCompare.AP';
import {
    CategoryTitleStylesDS,
    LegalMentionsStylesDS,
    RowCellGearboxStylesDS,
    RowCellMainStylesDS,
    RowTitleStylesDS,
    TitleStylesDS,
} from './DS/ModalEngineCompare.DS';
import {
    CategoryTitleStylesOV_GB,
    RowCellGearboxStylesOV_GB,
    RowCellMainStylesOV_GB,
    RowTitleStylesOV_GB,
} from './OV/GB/ModalEngineCompare.OV';
import {
    CategoryTitleStylesOV,
    RowCellGearboxStylesOV,
    RowCellMainStylesOV,
    RowTitleStylesOV,
} from './OV/ModalEngineCompare.OV';

interface IEngineComparatorRowProps {
    items: number;
    hasTitle?: boolean;
    sticky?: number;
    dark?: boolean;
    shadow?: boolean;
}

interface IEngineComparatorRowCellProps {
    idx?: number;
    head?: boolean;
    gearbox?: boolean;
}

interface IEngineComparatorRowTitleProps {
    start?: number;
    end?: number;
}

export const Wrapper = styled.div`
    width: 100%;

    .gridWrap {
        position: relative;
        width: 100%;
    }

    .grid {
        position: relative;
    }

    .closeButton {
        position: sticky;
        float: right;
    }

    .backBtn {
        cursor: pointer;
        background: none;
        border: none;
        padding: ${rem(5)};
        height: ${rem(25)};
        position: sticky;
        top: 0;
        z-index: 999999;

        svg {
            width: ${rem(25)};
            height: ${rem(25)};
        }
    }
`;

export const Row = styled.div<IEngineComparatorRowProps>`
    ${invisibleScrollbars};

    align-items: center;
    background: #fff;
    color: ${({ theme }) => theme.colors.black};
    display: grid;
    gap: ${rem(12)} ${rem(45)};

    ${({ items, hasTitle }) =>
        items &&
        css`
            grid-template-areas:
                ${hasTitle &&
                `'${Array(items)
                    .fill(0)
                    .map((_, i) => `title-${i + 1}`)
                    .join(' ')}'`}
                '${Array(items)
                    .fill(0)
                    .map((_, i) => `data-${i + 1}`)
                    .join(' ')}';
            grid-template-columns:
                repeat(${items - 1}, [col-start] ${rem(176)} [col-end])
                [col-start] ${rem(176)} [col-end col-last];
        `}

    overflow: auto hidden;
    padding: ${rem(16)} ${rem(40)};
    position: relative;
    transition: all 0.3s;
    z-index: 0;

    @media (max-width: ${breakpoints.sm}px) {
        padding: ${rem(15)};
    }

    ${({ sticky }) =>
        sticky >= 0 &&
        css`
            position: sticky;
            top: ${rem(sticky)};
            z-index: ${sticky === 0 ? 6 : 5};
        `}

    ${({ dark }) =>
        dark &&
        css`
            background: #f7f7f7;
        `}

    ${({ shadow }) =>
        shadow &&
        css`
            box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
        `}
`;

export const CategoriesSection = styled.div`
    display: flex;
    justify-content: center;
    background: white;
    position: sticky;
    top: ${rem('215px')};
    z-index: 7;
    border-top: 1px solid ${({ theme }) => theme.colors.grey12};

    ${isBrandAC &&
    css`
        border-top: 1px solid ${({ theme }) => theme.colors.grey5};

        @media (min-width: ${breakpoints.md}px) {
            border-bottom: 1px solid ${({ theme }) => theme.colors.grey5};
        }
    `}

    ${isBrandOV &&
    css`
        border-top: 1px solid ${({ theme }) => theme.colors.grey25};

        @media (min-width: ${breakpoints.md}px) {
            border-bottom: 1px solid ${({ theme }) => theme.colors.grey25};
        }
    `}
    
    ${isBrandOV &&
    isMarketGB &&
    css`
        border-top: 1px solid ${({ theme }) => theme.colors.grey25};

        @media (min-width: ${breakpoints.md}px) {
            border-bottom: 1px solid ${({ theme }) => theme.colors.black};
        }
    `}
    
    ${isBrandDS &&
    css`
        border-color: ${({ theme }) => theme.colors.grey9};
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey9};

        @media (min-width: ${breakpoints.md}px) {
            border-bottom: unset;
        }
    `}

    .categories {
        display: flex;
        flex-wrap: wrap;
        color: #3b3938;
        padding: 0 2.5rem;
        font-weight: 600;
        font-size: 1rem;
    }

    .desktop {
        display: none;

        @media (min-width: ${breakpoints.md}px) {
            display: flex;
        }
    }

    .mobile {
        padding: ${rem(15)} 0 ${rem(5)} ${rem(20)};
        width: 100%;

        &.hideOnDesktop {
            @media (min-width: ${breakpoints.md}px) {
                display: none;
            }
        }
    }
`;

export const RowCell = styled.div<IEngineComparatorRowCellProps>`
    align-items: flex-start;
    display: flex;
    height: 100%;

    ${isBrandAC && RowCellMainStylesAC};
    ${isBrandAP && RowCellMainStylesAP};
    ${isBrandDS && RowCellMainStylesDS};
    ${isBrandOV && (isMarketGB ? RowCellMainStylesOV_GB : RowCellMainStylesOV)};

    ${({ idx }) =>
        idx &&
        css`
            grid-area: data-${idx};
        `}

    ${({ head }) =>
        head &&
        css`
            align-items: flex-start;
            color: #3b3938;
            flex-direction: column;
            gap: ${rem(4)};

            @media (max-width: ${breakpoints.sm}px) {
                padding-top: ${rem(10)};
            }
        `}

  ${({ gearbox }) =>
        gearbox &&
        css`
            ${isBrandAC && RowCellGearboxStylesAC};
            ${isBrandAP && RowCellGearboxStylesAP};
            ${isBrandDS && RowCellGearboxStylesDS};
            ${isBrandOV && (isMarketGB ? RowCellGearboxStylesOV_GB : RowCellGearboxStylesOV)}
        `}
`;

export const RowTitle = styled.div<IEngineComparatorRowTitleProps>`
    color: #73767a;
    grid-column-start: col-start 1;
    grid-column-end: col-last;

    ${isBrandAC && RowTitleStylesAC};
    ${isBrandAP && RowTitleStylesAP};
    ${isBrandDS && RowTitleStylesDS};
    ${isBrandOV && (isMarketGB ? RowTitleStylesOV_GB : RowTitleStylesOV)};

    ${({ start, end }) =>
        start &&
        end &&
        css`
            grid-column-start: col-start ${start};
            grid-column-end: col-end ${end};
        `}
`;

export const Title = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey4};
    color: ${({ theme }) => theme.colors.black1};
    font-size: ${rem('20px')};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    margin: 0 ${rem(40)} ${rem(15)};
    padding: ${rem(40)} 0;
    text-align: left;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${rem(16)};
        margin: 0 ${rem(15)} ${rem(15)};
        padding: ${rem(30)} 0;
    }

    ${isBrandDS && TitleStylesDS};
`;

export const CategoryTitle = styled.div`
    color: #3b3938;
    padding: ${rem(32)} ${rem(40)};
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    line-height: ${rem(20)};

    ${isBrandAC && CategoryTitleStylesAC};
    ${isBrandAP && CategoryTitleStylesAP};
    ${isBrandDS && CategoryTitleStylesDS};
    ${isBrandOV && (isMarketGB ? CategoryTitleStylesOV_GB : CategoryTitleStylesOV)};

    @media (max-width: ${breakpoints.sm}px) {
        padding: ${rem(15)};
    }
`;

export const Separator = styled.div<{ large?: boolean }>`
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey4};
    margin: ${({ large }) => `${rem(large ? 32 : 16)} ${rem(40)} 0`};

    @media (max-width: ${breakpoints.sm}px) {
        margin: 0 ${rem(15)};
    }
`;

export const LegalMentions = styled.div`
    color: #796f6e;
    font-size: ${rem(12)};
    line-height: ${rem(18)};
    margin: 0 ${rem(40)} ${rem(15)};
    padding: ${rem(40)} 0;
    text-align: left;
    position: relative;

    p {
        position: absolute;
    }

    @media (max-width: ${breakpoints.sm}px) {
        margin: 0 ${rem(15)} ${rem(15)};
        padding: ${rem(30)} 0;
    }

    ${isBrandDS && LegalMentionsStylesDS};
`;
