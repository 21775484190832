import styled from 'styled-components';
import { ModalDemoVehicleInfoStyled } from '../ModalDemoVehicleInfoStyled';
import { rem } from 'polished';

export const ModalDemoVehicleInfoStyledAP = styled(ModalDemoVehicleInfoStyled)`
    .title {
        font-size: ${({ theme }) => theme.fontSizes.textSubBase};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
    }

    .description {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
    }
`;
