import styled from 'styled-components';
import InputRadioTemplate from './InputRadioTemplate';
import { rem } from 'polished';

export const InputRadioStyled = styled(InputRadioTemplate)`
    margin-top: ${(props) => (props.marginTop ? rem(props.marginTop) : 0)};
    margin-bottom: ${(props) => (props.marginBottom ? rem(props.marginBottom) : 0)};

    [type='radio']:checked,
    [type='radio']:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type='radio']:checked + label,
    [type='radio']:not(:checked) + label {
        position: relative;
        padding-left: ${rem(30)};
        cursor: pointer;
        line-height: ${rem(20)};
        display: inline-block;
        color: ${({ theme }) => theme.colors.black};
        font-size: ${({ theme }) => theme.fontSizes.textBase};

        .description {
            display: block;
            color: ${({ theme }) => theme.colors.grey17};
        }
    }
    [type='radio']:checked + label:before,
    [type='radio']:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: ${rem(20)};
        height: ${rem(20)};
        border: 1px solid ${({ theme }) => theme.colors.grey17};
        border-radius: 100%;
        background: ${({ theme }) => theme.colors.white};
    }
    [type='radio']:checked + label:after,
    [type='radio']:not(:checked) + label:after {
        content: '';
        width: ${rem(10)};
        height: ${rem(10)};
        background: ${({ theme }) => theme.colors.primary};
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 100%;
        transition: all 0.2s ease;
    }
    [type='radio']:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }
    [type='radio']:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }
`;
