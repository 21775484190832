import React, { FC, useState } from 'react';
import useTranslations from '@hooks/useTranslations';
import { DemoVehicleProps } from '@components/DemoVehicleLabel/index';
import Icon, { Icons } from '@components/Icon';
import UIDuck from '../../redux/commonDucks/ui.duck';
import { DEMO_VEHICLE_DEFAULT_DATE_FORMAT, ModalVersion } from '../../constants/main';
import { useDispatch } from 'react-redux';
import { VDStatus } from '../../services/stock/models/stockItems.service.model';
import { formatDateByLang } from '@utils/Date.utils';
import moment from 'moment';

const DemoVehicleLabelTemplate: FC<DemoVehicleProps> = ({ className, status, mileage, registrationDate }) => {
    const { t } = useTranslations();

    const dispatch = useDispatch();

    const getLabelByStatus = () => {
        switch (status) {
            case VDStatus.DEMO:
                return t('vehicle.demo.label');
            case VDStatus.SHOWROOM:
                return t('vehicle.showroom.label');
        }
    };

    return (
        <div className={className}>
            <div
                className="info"
                onClick={() => {
                    dispatch(
                        UIDuck.openModal({
                            data: {},
                            modalType: UIDuck.MODAL_TYPES.DEMO_VEHICLE_INFO,
                            modalVersion: ModalVersion.v2,
                            modalProperties: {},
                        })
                    );
                }}
            >
                {status ? getLabelByStatus() : t('offerListItem.demoLabel')}{' '}
                <Icon className="infoIcon" name={Icons.InfoCircle} width={14} height={14} />
            </div>
            {mileage && registrationDate && (
                <div className="details">
                    <span>
                        <span className="details-label">{t('vehicle.demo.mileage')}</span>
                        {new Intl.NumberFormat().format(Number(mileage))} {t('checkout.delivery.dealer.item.distance')}
                    </span>
                    <span>
                        <span className="details-label">{t('vehicle.demo.registrationDate')}</span>
                        {formatDateByLang(moment(registrationDate, DEMO_VEHICLE_DEFAULT_DATE_FORMAT).toDate())}
                    </span>
                </div>
            )}
        </div>
    );
};

export default DemoVehicleLabelTemplate;
