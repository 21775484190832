import styled from 'styled-components';
import { DemoVehicleLabelStyled } from '../DemoVehicleLabelStyled';
import { rem } from 'polished';

export const DemoVehicleLabelStyledDS = styled(DemoVehicleLabelStyled)`
    .info {
        background: ${({ theme }) => theme.colors.primary};
        border-radius: ${rem(8)};
        color: ${({ theme }) => theme.colors.white};
        font-size: ${({ theme }) => theme.fontSizes.h6};

        svg {
            g {
                circle,
                line {
                    stroke: ${({ theme }) => theme.colors.white};
                }
            }
        }
    }

    .details {
        color: ${({ theme }) => theme.colors.grey29};
        font-size: ${({ theme }) => theme.fontSizes.h6};

        &-label {
            color: ${({ theme }) => theme.colors.black};
        }
    }
`;
