import styled from 'styled-components';
import { rem } from 'polished';
import { ModalFeatureDemandStyled } from '../ModalFeatureDemandStyled';

export const ModalFeatureDemandStyledDS = styled(ModalFeatureDemandStyled)`
    .description {
        line-height: ${rem(22)};
    }

    .title {
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};
    }

    .sections {
        &_section {
            border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};

            &__description {
                line-height: ${rem(22)};
            }

            &__video {
                border-radius: ${rem(16)};
            }
        }
    }
`;
