import React, { Dispatch, SetStateAction } from 'react';
import { BRAND, isMarketGB } from 'src/constants/main';
import { SC } from 'src/styles/theme';
import { ModalDemoVehicleInfoStyledAC } from './AC/ModalDemoVehicleInfoStyled.AC';
import { ModalDemoVehicleInfoStyledAP } from './AP/ModalDemoVehicleInfoStyled.AP';
import { ModalDemoVehicleInfoStyledDS } from './DS/ModalDemoVehicleInfoStyled.DS';
import { ModalDemoVehicleInfoStyledOV } from './OV/ModalDemoVehicleInfoStyled.OV';
import { ModalDemoVehicleInfoStyledOV as ModalDemoVehicleInfoStyledOVGB } from './OV/GB/ModalDemoVehicleInfoStyled.OV';

export interface IModalDemoVehicleInfo extends SC {}

export const ModalDemoVehicleInfo = (props: IModalDemoVehicleInfo) => {
    if (BRAND === 'AP') return <ModalDemoVehicleInfoStyledAP {...props} />;
    if (BRAND === 'AC') return <ModalDemoVehicleInfoStyledAC {...props} />;
    if (BRAND === 'DS') return <ModalDemoVehicleInfoStyledDS {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? <ModalDemoVehicleInfoStyledOVGB {...props} /> : <ModalDemoVehicleInfoStyledOV {...props} />;
};
