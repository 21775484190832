import React, { FunctionComponent, memo, useContext } from 'react';
import { ICarDetailsConfigurationOptionsProps } from './ICarDetailsOptions';
import { ICarOptionsList } from '../../CheckoutMySummary/interfaces';
import parser from 'bbcode-to-react';
import useTranslations from '@hooks/useTranslations';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';
import { getTotalOptionOrColorPrice } from '@utils/Price.utils';
import { useSelector } from 'react-redux';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST, FeatureSwitchContext } from '../../../context/featureSwitchApp';
import PromoCodeTemplate from '../../PromoCodeResult';
import carDetailsDuck from '../../../redux/carDetails/carDetails.duck';
import { IDealerFee } from '../../DealerFees/DealerFeesTemplate';
import { usePrices } from '@hooks/usePriceCalculator';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { useJourneyType } from '@hooks/useJourneyType';
import { IAccessory } from '../../../services/carDetails/carDetails.types';
import { ICarAccessory } from '../../../interfaces/Car';
import { Redux } from '../../../redux/redux.interface';
import ConfiguratorDuck from '../../../redux/configurator/configurator.duck';
import { getFeatureSwitch } from '@utils/featureSwitches.utils';
import { PxVersion } from '../../../constants/main';

const CheckoutCarDetailsConfigurationOptions: FunctionComponent<ICarDetailsConfigurationOptionsProps> = memo(
    ({
        carDetail,
        exteriorColor,
        exteriorColorPrice,
        interiorColour,
        interiorColourPrice,
        options,
        order,
    }: ICarDetailsConfigurationOptionsProps) => {
        const { t } = useTranslations();
        const context = useContext(FeatureSwitchContext);
        const { formatMoney } = useMoneyFormatter();
        const { paymentJourneyType, isCash, isRent } = useJourneyType();
        const { cash } = usePrices(carDetail ?? null, false, null, true, false, isCash ? 'cash' : 'finance', isCash);
        const { promoCodeInDeal, carDetails, currentDeal } = useCarDetailsDuck();
        const pxVersion = getFeatureSwitch(FEATURES_LIST.FEATURE_SWITCH_PX_VERSION, context);
        const dealerFees =
            useSelector((state) => carDetailsDuck.getOwnState(state)?.currentCarDetails?.fees) || carDetail?.fees || [];
        const partExchange = useSelector((state: Redux) => ConfiguratorDuck.getOwnState(state).pxState);

        const isDealerLayerEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_DEALER_LAYER_ENABLED);
        const isDealerFeesEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_DEALER_FEES_ENABLED);

        const optionalDealerFees = dealerFees.filter((dealerFee: IDealerFee) => !dealerFee.mandatory);
        const mandatoryDealerFees = dealerFees.filter((dealerFee: IDealerFee) => dealerFee.mandatory);
        const selectedAccessories = carDetail?.accessories?.filter(
            (accessory: IAccessory | ICarAccessory) => accessory.selected
        );

        const optionalDealerFeesPrice = optionalDealerFees.reduce(
            (sum: number, dealerFee: IDealerFee) => sum + Number(dealerFee.priceInclTax),
            0
        );
        const mandatoryDealerFeesPrice = mandatoryDealerFees.reduce(
            (sum: number, dealerFee: IDealerFee) => sum + dealerFee.priceInclTax,
            0
        );

        const promoCode = order?.sibling.extraFields.promoCode || promoCodeInDeal;

        const comment = currentDeal?.extraFields?.comments && JSON.parse(currentDeal?.extraFields?.comments)?.[0]?.text;

        return (
            <div className="optionsWrapper">
                <div className="detailsContent">
                    <p className="optionsWrapperTitle">{t('checkout.carConfig.options')}</p>
                    <p className="paragraph">
                        <span data-testid="TESTING_EXTERIORCOLOR_TEXT">{exteriorColor}</span>
                        {typeof exteriorColorPrice !== 'undefined' && exteriorColorPrice !== null ? (
                            <span>
                                <span data-testid="TESTING_EXTERIORCOLOR_PRICE">
                                    {!isRent ? formatMoney(exteriorColorPrice, isDealerLayerEnabled) : ''}
                                </span>
                            </span>
                        ) : (
                            <span>{t('checkout.carConfig.price.undefined')}</span>
                        )}
                    </p>
                    <p className="paragraph">
                        <span data-testid="TESTING_INTERIORCOLOR_TEXT">{interiorColour}</span>
                        {typeof interiorColourPrice !== 'undefined' && interiorColourPrice !== null ? (
                            <span>
                                <span data-testid="TESTING_INTERIORCOLOR_PRICE">
                                    {!isRent ? formatMoney(interiorColourPrice, isDealerLayerEnabled) : ''}
                                </span>
                            </span>
                        ) : (
                            <span>{t('checkout.carConfig.price.undefined')}</span>
                        )}
                    </p>
                    {options?.map((option: ICarOptionsList, index: number) => {
                        return (
                            <p key={index} className="paragraph">
                                <span data-testid={`TESTING_OPTION_TEXT_${index}`}>{option.title}</span>
                                <span data-testid={`TESTING_OPTION_PRICE_${index}`}>
                                    {!isRent
                                        ? formatMoney(
                                              getTotalOptionOrColorPrice(option, paymentJourneyType),
                                              isDealerLayerEnabled
                                          )
                                        : ''}
                                </span>
                            </p>
                        );
                    })}
                    {!isRent && currentDeal?.extraFields?.agent_discount && (
                        <p className="paragraph">
                            <span>{t('basket.summary.dealerDiscount')}</span>
                            <span>
                                -{' '}
                                {formatMoney(
                                    Number(JSON.parse(currentDeal?.extraFields?.agent_discount)?.amount),
                                    false
                                )}
                            </span>
                        </p>
                    )}
                    <PromoCodeTemplate promoCode={promoCode} />
                </div>
                {dealerFees?.length > 0 && isDealerFeesEnabled ? (
                    <>
                        {!isRent && <div className="separatorLine" />}
                        {!isRent && (
                            <p className="totalPrice">
                                <span>{t('offerDetail.finalPrice')}</span>
                                <span>
                                    <span>
                                        {formatMoney(
                                            cash.totalPrice - mandatoryDealerFeesPrice - optionalDealerFeesPrice,
                                            false
                                        )}
                                    </span>
                                </span>
                            </p>
                        )}
                        <div className="separatorLine" />
                        {!isRent && mandatoryDealerFees.length > 0 && (
                            <div className="detailsContent">
                                <p className="optionsWrapperTitle">
                                    {t('basket.orderSummary.mandatoryDealerFees.title')}
                                </p>
                                {mandatoryDealerFees.map((dealerFee: IDealerFee, key: number) =>
                                    dealerFee.id === 'carte_grise' ? (
                                        dealerFee.isUsed && (
                                            <p key={key} className="paragraph">
                                                <span>
                                                    {t('basket.dealerFee.carteGrise.title')} -{' '}
                                                    {t('basket.carteGreisDealerFee.appliedPostalCode', {
                                                        postalCode: dealerFee.postalCode,
                                                    })}
                                                </span>
                                                <span>
                                                    <span>{formatMoney(dealerFee.priceInclTax)}</span>
                                                </span>
                                            </p>
                                        )
                                    ) : (
                                        <p key={key} className="paragraph">
                                            <span>{t(`${dealerFee.title}`)}</span>
                                            <span>
                                                <span>{!isRent ? formatMoney(dealerFee.priceInclTax) : ''}</span>
                                            </span>
                                        </p>
                                    )
                                )}
                                <div className="separatorLine" />
                                <p className="totalPrice">
                                    <span>{t('basket.orderSummary.mandatoryDealerFees.totalPrice')}</span>
                                    <span>
                                        <span>{formatMoney(mandatoryDealerFeesPrice)}</span>
                                    </span>
                                </p>
                                <div className="separatorLine" />
                            </div>
                        )}
                        {optionalDealerFees.length > 0 && (
                            <div className="detailsContent">
                                <p className="optionsWrapperTitle">
                                    {t('basket.orderSummary.optionalDealerFees.title')}
                                </p>
                                {optionalDealerFees.map((dealerFee: IDealerFee, key: number) => (
                                    <p key={key} className="paragraph">
                                        <span>{t(`${dealerFee.title}`)}</span>
                                        <span>
                                            <span>{formatMoney(dealerFee.priceInclTax)}</span>
                                        </span>
                                    </p>
                                ))}
                                <div className="separatorLine" />
                                <p className="totalPrice">
                                    <span>{t('basket.orderSummary.optionalDealerFees.totalPrice')}</span>
                                    <span>
                                        <span>{formatMoney(optionalDealerFeesPrice)}</span>
                                    </span>
                                </p>
                            </div>
                        )}
                    </>
                ) : null}
                {selectedAccessories?.length > 0 && (
                    <>
                        <div className="separatorLine" />
                        <div className="detailsContent">
                            <p className="optionsWrapperTitle">{t('basket.orderSummary.optionalAccessories.title')}</p>
                            {selectedAccessories.map((accessory: IAccessory | ICarAccessory, key: number) => (
                                <p key={key} className="paragraph">
                                    <span>{parser.toReact(accessory.label)}</span>
                                    <span>
                                        <span>{formatMoney(accessory.netAmount)}</span>
                                    </span>
                                </p>
                            ))}
                            <div className="separatorLine" />
                            <p className="totalPrice">
                                <span>{t('basket.orderSummary.optionalAccessories.totalPrice')}</span>
                                <span>
                                    <span>{formatMoney(carDetail?.importInputs?.accessoriesPrice)}</span>
                                </span>
                            </p>
                        </div>
                    </>
                )}
                {pxVersion === PxVersion.v2 && currentDeal?.partExchangeId && isCash && (
                    <>
                        <div className="separatorLine" />
                        <div className="detailsContent">
                            <p className="optionsWrapperTitle">{t('basket.summary.px.title')}</p>
                            {partExchange?.price && (
                                <p className="paragraph">
                                    <span>{t('basket.summary.px.trade')}</span>
                                    <span>
                                        <span>{formatMoney(partExchange?.price)}</span>
                                    </span>
                                </p>
                            )}
                            {partExchange?.bonusAmount > 0 && (
                                <p className="paragraph">
                                    <span>{t('basket.summary.px.bonus')}</span>
                                    <span>
                                        <span>{formatMoney(partExchange?.bonusAmount)}</span>
                                    </span>
                                </p>
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    }
);
CheckoutCarDetailsConfigurationOptions.displayName = 'CheckoutCarDetailsConfigurationOptions';
export default CheckoutCarDetailsConfigurationOptions;
