import React, { FunctionComponent } from 'react';

import { ICarDetailsPriceProps } from './ICarDetailsOptions';
import useTranslations from '@hooks/useTranslations';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';
import { FEATURES_LIST } from 'src/context/featureSwitchApp';
import { PaymentJourneyTypes } from 'src/partExchange/interfaces/Default';
import DataRenderer from './DataRenderer';
import { getEcologicalBonus } from '@utils/Car.utils';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { Trans } from 'react-i18next';
import PriceSuffix from "@components/PriceSuffix";
import {useJourneyType} from "@hooks/useJourneyType";

const CheckoutCarDetailsPrice: FunctionComponent<ICarDetailsPriceProps> = ({
    priceTypeTranslation,
    price,
    className,
    dataTestId,
    subTitle,
    fee,
    journeyType,
    showScrappageSummary = false,
    isPriceWithoutTax = false,
    showEcobonus = false,
    carDetail,
}: ICarDetailsPriceProps) => {
    const { formatMoney } = useMoneyFormatter();
    const { t } = useTranslations();
    const { carDetails: carDetailsFromDuck } = useCarDetailsDuck();
    const { isRent } = useJourneyType();
    const carDetails = carDetail || carDetailsFromDuck;

    const isExtraFeeInfoEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SHOW_REMAINING_TO_PAY_INFO);
    const displayFee = Boolean(fee && journeyType === PaymentJourneyTypes.CASH);
    const isAllowedScrappage = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SHOW_SCRAPPAGE_MODAL);
    const displayEcoBonus = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SHOW_ECO_BONUS) && showEcobonus;

    const remainingTotal = fee ? price - fee : 0;

    const { hasEcologicalBonus, ecologicalBonusValue, ecologicalBonusTitleKey } = getEcologicalBonus(carDetails);

    return (
        <div className={className}>
            <div className={`priceInfoWrapper ${isPriceWithoutTax ? 'withoutTax' : ''}`}>
                <span className="priceInfoWrapperTitle">
                    {priceTypeTranslation}
                    {subTitle && <span className="priceInfoWrapper__subTitle"> {subTitle}</span>}
                </span>
                <span className={`price ${isPriceWithoutTax ? 'withoutTax' : ''}`} data-testid={dataTestId}>
                    {formatMoney(price, false)}
                    <PriceSuffix
                        showOnlyPrioritizedTranslation
                        checkPrioritizedTranslation
                        showPriceExcludedVat={isPriceWithoutTax}
                        isMonthlyPrice={isRent}
                    />
                </span>
            </div>
            {hasEcologicalBonus && displayEcoBonus && (
                <DataRenderer
                    className={className}
                    data={[
                        {
                            title: t(ecologicalBonusTitleKey),
                            priceInclTax:
                                ecologicalBonusValue > 0 ? ecologicalBonusValue : String(t('offerDetail.bonus.amount')),
                        },
                    ]}
                />
            )}
            {showScrappageSummary && isAllowedScrappage && (
                <div
                    className={`scrappageWrapper`}
                    dangerouslySetInnerHTML={{ __html: t('checkout.scrappage.summary') }}
                />
            )}
            {displayFee && (
                <div className={`reservationFeeWrapper`}>
                    <span>{t('checkout.reservationFee.label')}</span>
                    <span>{formatMoney(fee, true)}</span>
                </div>
            )}
            {displayFee && isExtraFeeInfoEnabled && (
                <div className={`reservationFeeExtraInfo`}>
                    <p>
                        {t('checkout.reservationFee.extraInfo.beforePrice')}
                        <strong> {formatMoney(remainingTotal)} </strong>
                        {t('checkout.reservationFee.extraInfo.afterPrice')}
                    </p>
                </div>
            )}
        </div>
    );
};

export default CheckoutCarDetailsPrice;
