import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import React from 'react';
import CheckoutMyDetailsTemplate from './CheckoutMyDetailsTemplate';
import { rem } from 'polished';
import media from '../../styles/media';

export const CheckoutMyDetailsStyled = styled((props) => <CheckoutMyDetailsTemplate {...props} />)`
    .continue-button {
        max-width: ${rem('160px')};
    }
    .wrapper {
        margin: ${rem('40px')} auto;
        max-width: 100%;

        @media (max-width: ${breakpoints.md}px) {
            margin: 0 auto;
            div {
                max-width: 100%;
                margin-right: 0;
                margin-left: 0;
            }
            &-cols {
                padding-left: 0;
                padding-right: 0;
            }
        }

        ${media.lg`
            width: 1140px;

        `}
        &-title {
            font-size: ${rem('29px')};
            text-transform: uppercase;
            color: ${({ theme }) => theme.colors.blue7};
            font-weight: ${({ theme }) => theme.fontWeights.textMedium};
            margin: 0;
            @media (min-width: ${breakpoints.xs}px) {
                margin: ${rem('64px')} 0 ${rem('16px')} ${rem('8px')};
            }
            @media (max-width: ${breakpoints.md}px) {
                font-size: ${rem('18px')};
                font-weight: bold;
                line-height: ${rem('24px')};
                margin: ${rem(15)} auto;
            }
        }
        &-space {
            display: flex;
            justify-content: space-between;
        }
        &-rows {
            display: flex;
            justify-content: space-between;
            margin-bottom: ${rem('32px')};
        }
        &-cols {
            width: 100%;
        }
    }
    .continue-button {
        margin-bottom: ${rem('40px')};
    }
    .dealerSpinner {
        .spinner {
            margin: 0 auto;
            div {
                border-color: ${({ theme }) => theme.colors.grey7} transparent transparent transparent;
            }
        }
    }
    .email-section,
    .personal-section,
    .b2b-section,
    .address-section {
        margin-bottom: ${rem(20)};
        border: 1px solid ${({ theme }) => theme.colors.grey8};
        padding: ${rem(20)};

        &-row {
            display: flex;
            flex-direction: column;

            @media (min-width: ${breakpoints.xs}px) {
                flex-direction: unset;
            }
        }

        .title {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            font-size: ${({ theme }) => theme.fontSizes.h4};
            margin-bottom: ${rem(20)};
        }

        .label {
            margin-top: -${rem(15)};
            font-size: ${({ theme }) => theme.fontSizes.h6};
            margin-bottom: ${rem(20)};
        }
    }
    .confirm-section {
        display: flex;
        justify-content: flex-end;
    }
    .required-section {
        margin-top: -${rem(5)};

        p {
            font-size: ${({ theme }) => theme.fontSizes.h6};
        }
    }
    .input-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: ${({ theme }) => theme.fontSizes.h5};
        margin: ${rem(5)} 0;

        @media (min-width: ${breakpoints.xs}px) {
            width: 50%;
        }

        &.address {
            width: 100%;
        }

        &-title {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }

        &-select,
        &-input {
            margin: ${rem(2)} ${rem(10)} 0 0;

            &.optional {
                margin-top: ${rem(10)};
            }
        }

        input {
            border: 1px solid ${({ theme }) => theme.colors.grey8};
            padding: ${rem(10)};
            color: ${({ theme }) => theme.colors.black};

            &.error {
                border-color: ${({ theme }) => theme.colors.danger};
            }

            &.phone {
                padding-left: ${rem(50)};
            }
        }

        &-error {
            font-size: ${({ theme }) => theme.fontSizes.h6};
            color: ${({ theme }) => theme.colors.danger};
        }

        &-info-text {
            color: ${({ theme }) => theme.colors.black};
            padding: ${rem(4)} 0;

            .link-outside {
                background: none;
                text-decoration: underline;
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
                border: none;
                padding: 0;
                cursor: pointer;
            }
        }

        &-info {
            font-size: ${({ theme }) => theme.fontSizes.h6};
            color: ${({ theme }) => theme.colors.grey9};
            margin-top: ${rem(2)};
        }
    }
    .wrapper-login {
        padding: ${rem(10)};

        button {
            background: none;
            text-decoration: underline;
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            border: none;
            padding: 0;
            cursor: pointer;

            &:hover {
                color: ${({ theme }) => theme.colors.primary};
            }
        }
    }
    .privacy {
        font-size: ${({ theme }) => theme.fontSizes.h5};
        padding-top: ${rem(15)};

        p {
            font-size: ${({ theme }) => theme.fontSizes.h5};
        }

        a {
            color: ${({ theme }) => theme.colors.black};
        }

        .title {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            display: block;
            padding-top: ${rem(10)};
        }

        .otherLegalMention1,
        .otherLegalMention2 {
            margin-bottom: ${rem(10)};
        }
    }
`;
