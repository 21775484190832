export const isValidLuhnAlgorithm = (number: number | string) => {
    if (!number) {
        return false;
    }

    const digits = number
        .toString()
        .replace(/\s/g, '')
        .split('')
        .map(Number);

    let sum = 0;
    let isAlternate = false;
    
    for (let i = digits.length - 1; i >= 0; i--) {
        let digit = digits[i];
        
        if (isAlternate) {
            digit *= 2;

            if (digit > 9) {
                digit -= 9;
            }
        }
        
        sum += digit;
        isAlternate = !isAlternate;
    }
    
    return sum % 10 === 0;
}
