import React from 'react';
import useTranslations from '@hooks/useTranslations';
import { IModalDemoVehicleInfo } from './index';

export const ModalDemoVehicleInfoTemplate = ({ className }: IModalDemoVehicleInfo) => {
    const { t } = useTranslations();

    return (
        <div className={className}>
            <div className="section">
                <span className="title">{t('modal.demoVehicleInfo.info.label')}</span>
                <p
                    className="description"
                    dangerouslySetInnerHTML={{ __html: t('modal.demoVehicleInfo.info.description') }}
                />
            </div>
        </div>
    );
};
