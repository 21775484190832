import React, { FC } from 'react';
import { InputRadioProps } from './index';

const InputRadioTemplate: FC<InputRadioProps> = ({
    className,
    marginTop,
    marginBottom,
    label,
    isChecked,
    onClick,
    description,
}) => (
    <div className={className}>
        <input type="radio" id={label} name={label} onClick={() => onClick()} checked={isChecked} />
        <label htmlFor={label}>
            {label}
            {description && <span className="description">{description}</span>}
        </label>
    </div>
);

export default InputRadioTemplate;
