import React, { Dispatch, SetStateAction } from 'react';
import { BRAND, isMarketGB } from 'src/constants/main';
import { SC } from 'src/styles/theme';
import { ModalFeatureDemandStyledAC } from './AC/ModalFeatureDemandStyled.AC';
import { ModalFeatureDemandStyledAP } from './AP/ModalFeatureDemandStyled.AP';
import { ModalFeatureDemandStyledDS } from './DS/ModalFeatureDemandStyled.DS';
import { ModalFeatureDemandStyledOV } from './OV/ModalFeatureDemandStyled.OV';
import { ModalFeatureDemandStyledOV as ModalFeatureDemandStyledOVGB } from './OV/GB/ModalFeatureDemandStyled.OV';

export interface IModalFeatureDemand extends SC {}

export const ModalFeatureDemand = (props: IModalFeatureDemand) => {
    if (BRAND === 'AP') return <ModalFeatureDemandStyledAP {...props} />;
    if (BRAND === 'AC') return <ModalFeatureDemandStyledAC {...props} />;
    if (BRAND === 'DS') return <ModalFeatureDemandStyledDS {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? <ModalFeatureDemandStyledOVGB {...props} /> : <ModalFeatureDemandStyledOV {...props} />;
};
