import Input from '../../../../ui/Input';
import InputRadio from '../../../../ui/InputRadio';
import React, { useEffect, useState } from 'react';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';
import Button from '@components/Button';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { usePrices } from '@hooks/usePriceCalculator';
import PriceSuffix from '@components/PriceSuffix';
import { SC } from '../../../../styles/theme';
import useTranslations from '@hooks/useTranslations';
import Icon, { Icons } from '@components/Icon';
import { DEPOSIT_TYPE } from '@components/Modal/ModalPersonalizeOffer/ModalPersonalizeOfferTemplate';
import { useJourneyType } from '@hooks/useJourneyType';
import { PaymentJourneyTypes } from '../../../../partExchange/interfaces/Default';
import { useGTM } from '@hooks/useGTM';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../../../../context/featureSwitchApp';

interface IDeposit extends SC {
    amount: number;
    setAmount: any;
    customDealerDiscountAmount: number;
    depositType: string;
    setDepositType: any;
}

export const DepositTemplate = ({
    amount,
    setAmount,
    customDealerDiscountAmount,
    depositType,
    setDepositType,
}: IDeposit) => {
    const { t } = useTranslations();

    const { formatMoney } = useMoneyFormatter();

    const { currentDeal } = useCarDetailsDuck();

    const { paymentJourneyType } = useJourneyType();

    const { pushToDataLayer } = useGTM();

    const { cash, finance } = usePrices();

    const { totalPrice: cashPrice } = cash;

    const [appliedFee, setAppliedFee] = useState(
        currentDeal?.extraFields?.dealerReservationFee || currentDeal?.extraFields?.reservationFee
    );
    const [inputFee, setInputFee] = useState(amount);
    const [previewFee, setPreviewFee] = useState(amount);

    const [appliedDealerDiscount, setAppliedDealerDiscount] = useState(
        currentDeal?.extraFields?.agent_discount
            ? Number(JSON.parse(currentDeal?.extraFields?.agent_discount)?.amount)
            : 0
    );

    const showPaymentDeposit = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SHOW_PAYMENT_DEPOSIT);

    useEffect(() => {
        setAppliedFee(currentDeal?.extraFields?.dealerReservationFee || currentDeal?.extraFields?.reservationFee);
        setAppliedDealerDiscount(
            currentDeal?.extraFields?.agent_discount
                ? Number(JSON.parse(currentDeal?.extraFields?.agent_discount)?.amount)
                : 0
        );
    }, [currentDeal]);

    const preview = () => {
        setAmount(inputFee);
        setPreviewFee(inputFee);
    };

    return paymentJourneyType != PaymentJourneyTypes.CASH ? (
        <div className="notEditableMessage">{t('modal.personalizeOffer.deposit.notEditable')}</div>
    ) : (
        <div>
            <div className="row center margin-top border-top border-bottom">
                <span className="row_label">{t('modal.personalizeOffer.deposit.deposit')}</span>
                <span className="row_value">{formatMoney(previewFee || appliedFee || 0, true)}</span>
            </div>
            {showPaymentDeposit && (
                <div className="row center border-bottom">
                    <span className="row_label">{t('modal.personalizeOffer.deposit.remainingAmount')}</span>
                    <span className="row_value">
                        {formatMoney(
                            cashPrice -
                                (previewFee || appliedFee || 0) +
                                (appliedDealerDiscount || 0) -
                                (customDealerDiscountAmount || 0)
                        )}
                    </span>
                </div>
            )}
            <div className="row center border-bottom">
                <span className="row_label bold">{t('modal.personalizeOffer.deposit.totalAmount')}</span>
                <span className="row_value">
                    {formatMoney(cashPrice + (appliedDealerDiscount || 0) - (customDealerDiscountAmount || 0))}{' '}
                    <PriceSuffix checkPrioritizedTranslation showOnlyPrioritizedTranslation />
                </span>
            </div>
            <div className="row">
                <InputRadio
                    onClick={() => {
                        pushToDataLayer({
                            event: 'uaevent',
                            eventCategory: 'Content::EditModal',
                            eventAction: 'Filter::Deposit',
                            eventLabel: 'online',
                        });
                        setDepositType(DEPOSIT_TYPE.ONLINE);
                    }}
                    label={t('modal.personalizeOffer.deposit.online')}
                    isChecked={depositType === DEPOSIT_TYPE.ONLINE}
                />
                <InputRadio
                    onClick={() => {
                        pushToDataLayer({
                            event: 'uaevent',
                            eventCategory: 'Content::EditModal',
                            eventAction: 'Filter::Deposit',
                            eventLabel: 'other',
                        });
                        setDepositType(DEPOSIT_TYPE.OFFLINE);
                    }}
                    label={t('modal.personalizeOffer.deposit.other')}
                    isChecked={depositType === DEPOSIT_TYPE.OFFLINE}
                />
            </div>
            <div className="row without-padding-top">
                <Input
                    hasError={inputFee && !/^[0-9]+$/.test(String(inputFee))}
                    value={inputFee || ''}
                    onChange={(value: number) => setInputFee(value)}
                    label={t('modal.personalizeOffer.deposit.input.label')}
                    placeholder={t('modal.personalizeOffer.deposit.input.placeholder')}
                    marginTop={20}
                />
                <Button onClick={preview} secondary disabled={!inputFee || !/^[0-9]+$/.test(String(inputFee))}>
                    {t('modal.personalizeOffer.deposit.input.button')}
                </Button>
                {inputFee !== undefined && inputFee > 0 && !/^[0-9]+$/.test(String(inputFee)) && (
                    <div className="row_error">{t('modal.personalizeOffer.deposit.input.error')}</div>
                )}
            </div>
            <button
                onClick={() => {
                    setAmount(currentDeal?.extraFields?.dealerReservationFeeOriginal);
                    setAppliedFee(
                        currentDeal?.extraFields?.dealerReservationFeeOriginal ||
                            currentDeal?.extraFields?.dealerReservationFee ||
                            currentDeal?.extraFields?.reservationFee
                    );
                    setPreviewFee(
                        currentDeal?.extraFields?.dealerReservationFeeOriginal ||
                            currentDeal?.extraFields?.dealerReservationFee ||
                            currentDeal?.extraFields?.reservationFee
                    );
                    setInputFee(null);
                }}
                className="reset-button without-margin-top"
            >
                <Icon name={Icons.Trash} />
                <span>{t('modal.personalizeOffer.deposit.reset')}</span>
            </button>
        </div>
    );
};
