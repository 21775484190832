import { isMobile } from 'is-mobile';
import {
    GtmAddToCart,
    GtmData,
    GtmPageLoad,
    GtmPageLoadParams,
    GtmProduct,
    GtmProductClick,
    GtmProductDetailImpression,
    GtmProductImpression,
    GtmShareConfigurationSuccessAction,
    GtmUserActionData,
    IGtmDealerData,
} from '../types/gtm';
import {
    GTM_BRAND,
    GTM_ENGINE_FUEL_CODE_TYPE,
    GTM_EVENT_CATEGORY,
    GTM_MAIN_STEP,
    GTM_OFFER_TYPE,
    GTM_ORDER_VARIANT,
    GTM_PAGE_CATEGORY,
    GTM_PAYMENT_JOURNEY,
    GTM_SITE_FAMILY,
    GTM_SITE_OWNER,
    GTM_SITE_TARGET,
    GTM_SITE_TYPE_LEVEL_1,
    GTM_SITE_TYPE_LEVEL_2,
    GTM_VIRTUAL_PAGE_URL,
    VehicleCategories,
} from '../constants/gtm';
import { CarJourneyType, FUEL, GEARBOX, PRICES, TBudgetType } from '../services';
import uniq from 'lodash/uniq';
import { IConfigurableCar } from '../interfaces/Car';
import { getPriceByType } from '@utils/Price.utils';
import { CATALOGUE_PRICE_TYPE, MRIP_PRICE_TYPE } from '../services/filters/filters';
import { IOfferItem } from '../interfaces/IOfferItem';
import { IS_B2B, LANGUAGE, MARKET } from '../constants/main';
import { PaymentStatuses } from '../constants/paymentStatuses';
import { IMyOrder, IMySavedDeal } from '../redux/user/user.duck.interface';
import { useMemo, useState } from 'react';
import { GTMFiltersActions } from '../interfaces/IFilters';
import { IPriceRange } from '../redux/filters/filter.duck.interface';
import { useTranslations } from '@hooks/useTranslations';
import { IVehicleTransformed, StockType } from '../services/stock/models/stockItems.service.model';
import { useAffiliationGtm } from '@hooks/useAffiliationGtm';
import { useGetCustomerType } from '@hooks/useGetCustomerType';
import { Pages } from '../types/pages';
import { isTest } from '@utils/runtime';
import { getCurrentCulture } from '@utils/getCurrentCulture';
import { ICarDetail } from '../redux/carDetails/carDetails.duck.interface';
import routes from 'src/constants/routes';
import useCustomRouter from './useCustomRouter';
import { useGetMainStepByPage } from './useGetMainSteps';
import { useSelector } from 'react-redux';
import UserDuck from '../redux/user/user.duck';
import { EUserLoginType } from '../interfaces/User';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { Redux } from '../redux/redux.interface';
import TrimSelectorDuck from '../redux/trimSelector/trimSelector.duck';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../context/featureSwitchApp';

export interface IDySpaEvent {
    fireSPAEvent: boolean;
    countAsPageview?: boolean;
}

export const useGTM = () => {
    const { t, i18n } = useTranslations();
    const { currentCulture } = getCurrentCulture(i18n);
    const [firstPageLoad, setFirstPageLoad] = useState(true);
    const [lastRecordedCheckoutStep, setLastRecordedCheckoutStep] = useState<number | null>(null);
    const [lastRecordedPurchaseId, setLastRecordedPurchaseId] = useState<string | null>(null);
    const affiliation = useAffiliationGtm();
    const customerType = useGetCustomerType();
    const { carDetails } = useCarDetailsDuck();
    const filteredTrims = useSelector((state: Redux) => TrimSelectorDuck.getFilteredTrims(state));
    const filteredStockTrims = useSelector((state: Redux) => TrimSelectorDuck.getFilteredStockTrims(state));
    const loginType = useSelector((state) => UserDuck.getOwnState(state).userAuth.loginType);
    const isDealerEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_IS_DEALER_CHOICE_ENABLED);

    const { getMainStepByPage } = useGetMainStepByPage();

    const getSiteTarget = () => {
        if (IS_B2B) {
            return 'B2B';
        }

        return 'B2C';
    };

    const router = useCustomRouter();

    const currentPath = router.asPath;
    const isTrimPage = currentPath.includes(routes.SELECTOR);
    const isBasketPage = currentPath.includes(routes.BASKET);
    const isConfigPage = currentPath.includes(routes.CAR);
    const isSummaryPage = currentPath.includes(routes.SUMMARY);

    const pageGTMTitle = useMemo(() => {
        if (isTrimPage) {
            return 'Vehicle Finition';
        } else if (isConfigPage) {
            return 'Personalisation';
        } else if (isSummaryPage) {
            return 'Summary';
        } else {
            return 'Basket';
        }
    }, [isTrimPage, isBasketPage, isConfigPage, isSummaryPage]);

    const getVehicleVariant = (car: IConfigurableCar | IOfferItem | IVehicleTransformed) => {
        return car?.exteriorColour?.title;
    };

    const getVehiclePrice = (prices: any[]) => {
        return getPriceByType(prices, MRIP_PRICE_TYPE) || getPriceByType(prices, CATALOGUE_PRICE_TYPE);
    };

    const getPageName = (virtualPageURL: string, vehicleModelBodystyle?: string) => {
        const deviceType = isMobile() ? 'mobile' : 'desktop';
        const pageTitle = virtualPageURL?.substr(virtualPageURL.lastIndexOf('/') + 1);

        let pageName = `${GTM_SITE_TYPE_LEVEL_1}/${GTM_SITE_TYPE_LEVEL_2}/${GTM_SITE_OWNER}/${GTM_SITE_TARGET}/${GTM_SITE_FAMILY}/${deviceType}`;
        if (vehicleModelBodystyle) {
            pageName += `/${vehicleModelBodystyle}`;
        }

        return `${pageName}/${pageTitle}`;
    };

    const getUserType = () => {
        if (loginType === EUserLoginType.ESELLER) {
            return 'eseller';
        }

        if (loginType === EUserLoginType.DEALER) {
            return 'dealer';
        }

        return 'customer';
    };

    const getUserActionCategory = () => {
        const { pathname } = location;

        if (pathname.includes('/trim')) {
            return 'Step1';
        }

        if (pathname.includes('/selector')) {
            return 'Step2';
        }

        if (pathname.includes('/basket')) {
            return 'Step3';
        }

        return 'Content';
    };

    const getBodyStyleLabel = (car: any) => {
        if (!car || Object.keys(car)?.length === 0) return undefined;

        const grBodyStyle = car.grBodyStyle || car.extraFields.grBodyStyle;
        return `${car.model.title} ${grBodyStyle.title}`;
    };

    const getVehicleBodyStyleLabel = (car: IConfigurableCar) => {
        return `${car.model.title} ${car.bodyStyle.title}`;
    };

    const mapCarData = (car: IConfigurableCar): GtmPageLoad => {
        if (!car) {
            return {};
        }
        const gearboxType = car.gearbox?.specs?.find((spec) => spec.key === 'type');
        const engineCO2 = car.engine?.specs?.find((spec) => spec.key === 'co2Combined');
        return {
            vehicleModelBodystyle: car.lcdv16?.substr(0, 6),
            vehicleModelBodystyleLabel: `${car.model.title} ${car.bodyStyle.title}`,
            vehicleCO2: engineCO2 ? engineCO2.value : '',
            vehicleFuel: car.fuelCode,
            vehicleVersionId: car.lcdv16,
            vehicleModelFamily: car.lcdv16?.substr(0, 4),
            vehicleFinition: car?.specPack.title,
            vehicleMotor: car.engine.id,
            vehicleMotorLabel: car.engine.title,
            vehicleEngineType: GTM_ENGINE_FUEL_CODE_TYPE[car?.fuelCode],
            vehicleGearboxLabelEN: gearboxType && gearboxType.label === 'Manuelle' ? 'manual' : 'automatic',
            vehicleBodyColor: car?.exteriorColour?.id,
            vehicleBodyColorLabel: car?.exteriorColour?.title,
        };
    };
    const mapDealerData = (dealer: any): IGtmDealerData => {
        return {
            edealerName: dealer.name,
            edealerSiteGeo: dealer.siteGeo,
            edealerID: dealer.externalId || dealer.siteGeo,
            edealerCity: dealer.city || dealer?.address?.[currentCulture]?.city,
            edealerIDLocal: undefined,
            edealerAddress: dealer.address,
            edealerPostalCode: dealer.postCode || dealer?.address?.[currentCulture]?.zipCode,
            edealerRegion: dealer.region,
            edealerPDV: undefined,
            edealerCountry: dealer.country,
        };
    };
    const mapProductData = (car: IConfigurableCar, category: string): GtmProduct => {
        return {
            name: getVehicleBodyStyleLabel(car),
            id: car?.lcdv16 ? car?.lcdv16 : car?.bodyStyle.id,
            price: getVehiclePrice(car.prices),
            brand: GTM_BRAND,
            variant: getVehicleVariant(car),
            category,
            quantity: 1,
        };
    };

    const mapPageVariant = (isStock?: boolean, budget?: TBudgetType, isCustomOffer?: boolean): string => {
        let pageVariant = ``;

        if (isStock !== undefined && !isStock) {
            pageVariant += GTM_ORDER_VARIANT.SOL;
        } else pageVariant += GTM_ORDER_VARIANT.STOCK;

        if (budget !== undefined) {
            const mappedBudget = budget === 'cash' ? GTM_PAYMENT_JOURNEY.CASH : GTM_PAYMENT_JOURNEY.FINANCE;

            pageVariant += `/${mappedBudget}`;
        }

        if (isCustomOffer !== undefined) {
            const offerType = isCustomOffer ? GTM_OFFER_TYPE.CUSTOM_OFFER : GTM_OFFER_TYPE.CLASSIC_ORDER;
            const divider = isStock !== undefined || budget !== undefined ? '/' : '';

            pageVariant += `${divider}${offerType}`;
        }

        return pageVariant;
    };

    const appendPaymentStatusToVirtualURL = (url: string, paymentStatus: PaymentStatuses) => {
        switch (paymentStatus) {
            case PaymentStatuses.failed:
                return `${url}/payment-error'`;

            case PaymentStatuses.financeRejected:
                return `${url}/payment-refused`;

            case PaymentStatuses.financeApproved:
                return `${url}/payment-approved`;

            default:
                return url;
        }
    };

    const extractVehicleData = (car: any = {}) => {
        let gearboxLabel = null;
        if (car.gearbox) {
            gearboxLabel = car.gearbox.specs.find((spec: any) => spec.key === 'type')?.label;
        }

        let vehicleGearboxLabelEN: 'manual' | 'automatic' = null;
        if (gearboxLabel) {
            const isManual = new RegExp('\\bmanual|manuelle\\b').test(gearboxLabel.toLowerCase());
            vehicleGearboxLabelEN = isManual ? 'manual' : 'automatic';
        }

        return {
            vehicleEngineType: GTM_ENGINE_FUEL_CODE_TYPE[car?.fuelCode],
            vehicleGearboxLabelEN,
            vehicleFinition: typeof car?.specPack !== 'undefined' ? car.specPack.title : undefined,
            vehicleModelBodystyle:
                typeof car?.lcdv16 !== 'undefined' ? (car.lcdv16?.substr(0, 6) as string) : undefined,
            vehicleModelBodystyleLabel:
                typeof car?.grBodyStyle == 'undefined' && typeof car?.extraFields == 'undefined'
                    ? undefined
                    : getBodyStyleLabel(car),
        };
    };

    const pushToDataLayer = (data: GtmData) => {
        if (!isTest()) {
            (window as any).dataLayer.push(data);
        }
    };

    const _handleDYPopulating = (pageCategory?: string, virtualPageUrl?: string) => {
        let pageType = '';
        let contextData = [];

        const isHomepage =
            (router.pathname === `/${CarJourneyType.CONFIGURABLE}` || router.pathname === `/${CarJourneyType.STOCK}`) &&
            !router.pathname?.includes(routes.SELECTOR);
        const isTrimProduct = router.pathname?.includes(routes.SELECTOR);
        const isSelectorProduct = router.pathname?.includes(routes.CAR);
        const isStockSummary = router.pathname?.includes(routes.SUMMARY) && carDetails?.stock;
        const isCart = router.pathname?.includes(routes.BASKET);

        if (isHomepage) {
            pageType = 'HOMEPAGE';
            contextData = [''];
        } else if (isTrimProduct) {
            pageType = 'PRODUCT';
            contextData = [filteredTrims?.[0]?.lcdv16?.slice(0, 6) || filteredStockTrims?.[0]?.lcdv16?.slice(0, 6)];
        } else if (isSelectorProduct) {
            pageType = 'PRODUCT';
            contextData = [carDetails?.lcdv16];
        } else if (isStockSummary) {
            pageType = 'PRODUCT';
            contextData = [carDetails?.lcdv16];
        } else if (isCart) {
            pageType = 'CART';
            contextData = [carDetails?.lcdv16];
        } else {
            pageType = 'OTHER';
            contextData = [virtualPageUrl];
        }

        (window as any).DY = (window as any).DY || {};
        (window as any).DY.recommendationContext = { type: pageType, data: contextData };
    };

    const pushPageLoad = async (params: GtmPageLoadParams) => {
        console.log('GTM__', firstPageLoad, getPageName(params.virtualPageURL, params.vehicleModelBodystyle));
        const record: GtmPageLoad = {
            event: 'updatevirtualpath',
            brand: GTM_BRAND,
            virtualPageURL: params.virtualPageURL,
            pageName: getPageName(params.virtualPageURL, params.vehicleModelBodystyle),
            language: LANGUAGE,
            country: MARKET,
            currency: t('currency'),
            siteTypeLevel1: 'SOL Retail',
            siteTypeLevel2: 'selling online',
            siteOwner: 'central',
            siteTarget: getSiteTarget(),
            siteFamily: 'new cars',
            pageCategory: params.pageCategory,
            vehicleCategory: VehicleCategories.VP,
            affiliation,
            customerType,
            userType: getUserType(),
            ...params,
        };

        if (firstPageLoad) {
            _handleDYPopulating(params.pageCategory, params.virtualPageURL);
        }

        pushToDataLayer({ ...record, ...params });

        setFirstPageLoad(false);
    };

    const pushHomePageLoad = (isStock: boolean) => {
        pushPageLoad({
            pageVariant: mapPageVariant(isStock),
            virtualPageURL: isStock ? GTM_VIRTUAL_PAGE_URL.STOCK_HOME : GTM_VIRTUAL_PAGE_URL.HOME,
            pageCategory: GTM_PAGE_CATEGORY.HOME,
            mainStepName: GTM_MAIN_STEP.HOME,
        });
    };

    const pushSummaryPage = (car: IConfigurableCar, isStock?: boolean, paymentJourneyType?: TBudgetType) => {
        const { vehicleGearboxLabelEN, vehicleEngineType } = extractVehicleData(car);
        const mainStep = getMainStepByPage(Pages.summary);

        pushPageLoad({
            event: 'updatevirtualpath',
            pageVariant: mapPageVariant(isStock, paymentJourneyType),
            virtualPageURL: (isStock
                ? GTM_VIRTUAL_PAGE_URL.SUMMARY_STOCK
                : GTM_VIRTUAL_PAGE_URL.SUMMARY_CONFIGURATOR
            ).replace('${step}', mainStep?.toString()),
            pageCategory: GTM_PAGE_CATEGORY.BASKET,
            mainStepName: isStock ? GTM_MAIN_STEP.STOCK : GTM_MAIN_STEP.CONFIGURATOR,
            mainStepIndicator: mainStep,
            vehicleEngineType,
            vehicleModelBodystyle: mapCarData(car).vehicleModelBodystyle,
            vehicleModelBodystyleLabel: mapCarData(car).vehicleModelBodystyleLabel,
            affiliation,
            customerType,
            vehicleGearboxLabelEN,
        } as GtmPageLoadParams);
    };

    const pushDealerPage = (
        car: IConfigurableCar,
        isStock?: boolean,
        paymentJourneyType?: TBudgetType,
        deal?: ICarDetail
    ) => {
        const { vehicleGearboxLabelEN } = extractVehicleData(car);
        const mainStep = getMainStepByPage(Pages.dealer);

        pushPageLoad({
            event: 'updatevirtualpath',
            pageVariant: mapPageVariant(isStock, paymentJourneyType),
            virtualPageURL: GTM_VIRTUAL_PAGE_URL.DEALER.replace('${step}', mainStep?.toString()),
            pageCategory: GTM_PAGE_CATEGORY.BASKET,
            mainStepName: GTM_MAIN_STEP.FIND_DEALER,
            mainStepIndicator: mainStep,
            vehicleModelBodystyle: mapCarData(car).vehicleModelBodystyle,
            vehicleModelBodystyleLabel: mapCarData(car).vehicleModelBodystyleLabel,
            vehicleEngineType: GTM_ENGINE_FUEL_CODE_TYPE[car?.fuelCode],
            vehicleBodyColor: deal?.fullProductConfiguration?.exteriorColour?.id,
            vehicleBodyColorLabel: deal?.fullProductConfiguration?.exteriorColour?.title,
            affiliation,
            customerType,
            vehicleGearboxLabelEN,
        } as GtmPageLoadParams);
    };

    const pushMyAccountPageLoad = (isStock: boolean = false) => {
        pushPageLoad({
            pageVariant: mapPageVariant(isStock),
            virtualPageURL: GTM_VIRTUAL_PAGE_URL.MY_ACCOUNT,
            pageCategory: GTM_PAGE_CATEGORY.MY_ACCOUNT,
            mainStepName: GTM_MAIN_STEP.MY_ACCOUNT,
        });
    };
    const pushMySavesPageLoad = (cars: IMySavedDeal[], isStock: boolean = false) => {
        const vehicleModelBodystyle = cars.map((car) => car.fullProductConfiguration.lcdv16?.substr(0, 6)).join('|');
        const vehicleModelBodystyleLabel = cars
            .map((car) => `${car.fullProductConfiguration.model.title} ${car.fullProductConfiguration.bodyStyle.title}`)
            .join('|');
        const vehicleEngineType = cars
            .map((car) => GTM_ENGINE_FUEL_CODE_TYPE[car?.fullProductConfiguration?.fuelCode])
            .join('|');
        const vehicleGearboxLabelEN = cars
            .map((car) => (car.fullProductConfiguration.gearboxLabel === 'Manuelle' ? 'manual' : 'automatic'))
            .join('|');

        pushPageLoad({
            pageVariant: mapPageVariant(isStock),
            virtualPageURL: GTM_VIRTUAL_PAGE_URL.MY_SAVES,
            pageCategory: GTM_PAGE_CATEGORY.MY_SAVES,
            mainStepName: GTM_MAIN_STEP.MY_SAVES,
            vehicleModelBodystyle,
            vehicleModelBodystyleLabel,
            vehicleEngineType: vehicleEngineType as any,
            vehicleGearboxLabelEN: vehicleGearboxLabelEN as any,
        });
    };

    const pushMyOrdersPageLoad = (cars: IMyOrder[], isStock: boolean = false) => {
        cars = cars.filter((car) => car.sibling);

        const vehicleModelBodystyle = cars
            .map((car) => car.sibling.fullProductConfiguration.lcdv16?.substr(0, 6))
            .join('|');
        const vehicleModelBodystyleLabel = cars
            .map(
                (car) =>
                    `${car.sibling.fullProductConfiguration.model.title} ${car.sibling.fullProductConfiguration.bodyStyle.title}`
            )
            .join('|');
        const vehicleEngineType = cars
            .map((car) => GTM_ENGINE_FUEL_CODE_TYPE[car?.sibling?.fullProductConfiguration?.fuelCode])
            .join('|');
        const vehicleGearboxLabelEN = cars
            .map((car) => (car.sibling.fullProductConfiguration.gearboxLabel === 'Manuelle' ? 'manual' : 'automatic'))
            .join('|');

        pushPageLoad({
            pageVariant: mapPageVariant(isStock),
            virtualPageURL: GTM_VIRTUAL_PAGE_URL.MY_ORDERS,
            pageCategory: GTM_PAGE_CATEGORY.MY_ORDERS,
            mainStepName: GTM_MAIN_STEP.MY_ORDERS,
            vehicleModelBodystyle,
            vehicleModelBodystyleLabel,
            vehicleEngineType: vehicleEngineType as any,
            vehicleGearboxLabelEN: vehicleGearboxLabelEN as any,
        });
    };

    const pushTrimPageLoad = (cars: IConfigurableCar[] | IVehicleTransformed[], isStock: boolean) => {
        const {
            vehicleModelBodystyle,
            vehicleGearboxLabelEN,
            vehicleModelBodystyleLabel,
            vehicleEngineType,
            vehicleFinition,
        } = extractVehicleData(cars[0]);
        let vehicleModelBodystyleLabelNonStock;
        if (!isStock) {
            vehicleModelBodystyleLabelNonStock = uniq(
                (cars as any).map(
                    (car: IConfigurableCar | IVehicleTransformed) => extractVehicleData(car).vehicleModelBodystyleLabel
                )
            ).join('|');
        }

        pushPageLoad({
            pageVariant: mapPageVariant(isStock),
            virtualPageURL: isStock ? GTM_VIRTUAL_PAGE_URL.STOCK_TRIM : GTM_VIRTUAL_PAGE_URL.TRIM,
            pageCategory: isStock ? GTM_PAGE_CATEGORY.STOCK : GTM_PAGE_CATEGORY.TRIM,
            mainStepIndicator: 1,
            mainStepName: isStock ? GTM_MAIN_STEP.STOCK : GTM_MAIN_STEP.TRIM,
            vehicleEngineType,
            vehicleModelBodystyle,
            vehicleModelBodystyleLabel: !isStock ? vehicleModelBodystyleLabelNonStock : vehicleModelBodystyleLabel,
            vehicleGearboxLabelEN,
        });
    };

    const pushConfiguratorPageLoad = (budget: TBudgetType, car: IConfigurableCar) => {
        const {
            vehicleGearboxLabelEN,
            vehicleModelBodystyle,
            vehicleModelBodystyleLabel,
            vehicleEngineType,
            vehicleFinition,
        } = extractVehicleData(car);

        pushPageLoad({
            virtualPageURL: GTM_VIRTUAL_PAGE_URL.CONFIGURATOR,
            pageVariant: mapPageVariant(car.stock, budget),
            pageCategory: GTM_PAGE_CATEGORY.CONFIGURATOR,
            mainStepIndicator: 2,
            mainStepName: GTM_MAIN_STEP.CONFIGURATOR,
            vehicleEngineType,
            vehicleGearboxLabelEN,
            vehicleModelBodystyle,
            vehicleModelBodystyleLabel: vehicleModelBodystyleLabel + ' ' + vehicleFinition,
        });
    };

    const pushBasketPageLoad = (
        budget: TBudgetType,
        car: IConfigurableCar,
        isStock: boolean = false,
        deal: ICarDetail
    ) => {
        const pageVariant = mapPageVariant(car.stock, budget);
        const mainStep = getMainStepByPage(Pages.basket);
        const {
            vehicleEngineType,
            vehicleGearboxLabelEN,
            vehicleModelBodystyle,
            vehicleModelBodystyleLabel,
            vehicleFinition,
        } = extractVehicleData(car);

        let virtualPageURL = ``;
        virtualPageURL += !isStock ? GTM_VIRTUAL_PAGE_URL.BASKET : GTM_VIRTUAL_PAGE_URL.BASKET_STOCK;
        virtualPageURL = virtualPageURL.replace('${step}', mainStep?.toString());

        if (budget !== undefined) {
            const mappedBudget = budget === 'cash' ? GTM_PAYMENT_JOURNEY.CASH : GTM_PAYMENT_JOURNEY.FINANCE;

            virtualPageURL += mappedBudget;
        }

        pushPageLoad({
            virtualPageURL,
            pageVariant,
            pageCategory: GTM_PAGE_CATEGORY.BASKET,
            mainStepIndicator: mainStep,
            mainStepName: GTM_MAIN_STEP.BASKET,
            vehicleGearboxLabelEN,
            vehicleModelBodystyle,
            vehicleEngineType,
            vehicleModelBodystyleLabel: vehicleModelBodystyleLabel + ' ' + vehicleFinition,
            vehicleBodyColor: deal?.fullProductConfiguration?.exteriorColour?.id,
            vehicleBodyColorLabel: deal?.fullProductConfiguration?.exteriorColour?.title,
        });
    };

    const pushErrorPageLoad = (errorCode: number, budget: TBudgetType) => {
        pushPageLoad({
            virtualPageURL: GTM_VIRTUAL_PAGE_URL.ERROR + errorCode,
            pageCategory: GTM_PAGE_CATEGORY.ERROR,
            pageVariant: mapPageVariant(false, budget),
        });
    };

    const pushDeliveryPageLoad = (car: IConfigurableCar, budget: TBudgetType, isCustomOffer: boolean) => {
        pushPageLoad({
            virtualPageURL: GTM_VIRTUAL_PAGE_URL.FIND_DEALER,
            pageVariant: mapPageVariant(car.stock, budget, isCustomOffer),
            pageCategory: GTM_PAGE_CATEGORY.CHECKOUT,
            mainStepIndicator: 1,
            mainStepName: GTM_MAIN_STEP.FIND_DEALER,
            ...mapCarData(car),
        });
    };

    const pushMyDetailsPageLoad = (car: IConfigurableCar, dealer: any, budget: TBudgetType, isCustomOffer: boolean) => {
        pushPageLoad({
            virtualPageURL: GTM_VIRTUAL_PAGE_URL.PERSONAL_DETAIL,
            pageVariant: mapPageVariant(car.stock, budget, isCustomOffer),
            pageCategory: GTM_PAGE_CATEGORY.CHECKOUT,
            mainStepIndicator: isDealerEnabled ? 1 : 2,
            mainStepName: GTM_MAIN_STEP.PERSONAL_DETAIL,
            ...mapCarData(car),
            ...mapDealerData(dealer),
        });
    };

    const pushPreOrderPageLoad = (car: IConfigurableCar, dealer: any, budget: TBudgetType, isCustomOffer: boolean) => {
        pushPageLoad({
            virtualPageURL: GTM_VIRTUAL_PAGE_URL.PRE_ORDER,
            pageVariant: mapPageVariant(car.stock, budget, isCustomOffer),
            pageCategory: GTM_PAGE_CATEGORY.CHECKOUT,
            mainStepIndicator: isDealerEnabled ? 2 : 3,
            mainStepName: GTM_MAIN_STEP.PRE_ORDER,
            ...mapCarData(car),
            ...mapDealerData(dealer),
        });
    };

    const pushOrderReviewPageLoad = (
        car: IConfigurableCar,
        dealer: any,
        budget: TBudgetType,
        isCustomOffer: boolean,
        paymentStatus: PaymentStatuses
    ) => {
        pushPageLoad({
            virtualPageURL: appendPaymentStatusToVirtualURL(GTM_VIRTUAL_PAGE_URL.ORDER_REVIEW, paymentStatus),
            pageVariant: mapPageVariant(car.stock, budget, isCustomOffer),
            pageCategory: GTM_PAGE_CATEGORY.CHECKOUT,
            mainStepIndicator: isDealerEnabled ? 3 : 4,
            mainStepName: GTM_MAIN_STEP.ORDER_REVIEW,
            ...mapCarData(car),
            ...mapDealerData(dealer),
        });
    };

    const pushConfirmationPageLoad = (
        car: IConfigurableCar,
        dealer: any,
        budget: TBudgetType,
        isCustomOffer: boolean
    ) => {
        pushPageLoad({
            virtualPageURL: GTM_VIRTUAL_PAGE_URL.CONFIRMATION,
            pageVariant: mapPageVariant(car.stock, budget, isCustomOffer),
            pageCategory: GTM_PAGE_CATEGORY.CHECKOUT,
            mainStepIndicator: isDealerEnabled ? 4 : 5,
            mainStepName: GTM_MAIN_STEP.CONFIRMATION,
            ...mapCarData(car),
            ...mapDealerData(dealer),
        });
    };

    const pushRequestForFinancingLldPageLoad = (
        car: IConfigurableCar,
        dealer: any,
        budget: TBudgetType,
        isCustomOffer: boolean
    ) => {
        pushPageLoad({
            virtualPageURL: GTM_VIRTUAL_PAGE_URL.REQUEST_FOR_FINANCING_LLD,
            pageVariant: mapPageVariant(car.stock, budget, isCustomOffer),
            pageCategory: GTM_PAGE_CATEGORY.CHECKOUT,
            mainStepIndicator: '3b',
            mainStepName: GTM_MAIN_STEP.REQUEST_FOR_FINANCING_LLD,
            ...mapCarData(car),
            ...mapDealerData(dealer),
        });
    };

    const pushConnectionPagePageLoad = (budget: TBudgetType) => {
        pushPageLoad({
            pageVariant: mapPageVariant(undefined, budget),
            virtualPageURL: GTM_VIRTUAL_PAGE_URL.CONNECTION,
            pageCategory: GTM_PAGE_CATEGORY.CONNECTION,
        });
    };

    const pushCheckoutEvent = (step: number, car: IConfigurableCar) => {
        if (lastRecordedCheckoutStep === step) {
            return;
        }

        pushToDataLayer({
            event: 'checkout',
            eventCategory: GTM_EVENT_CATEGORY,
            eventAction: 'Checkout',
            ecommerce: {
                checkout: {
                    actionField: {
                        step,
                        option: 'new user',
                    },
                    products: [mapProductData(car, `${getSiteTarget()} new car`)],
                },
            },
        });

        setLastRecordedCheckoutStep(step);
    };

    const pushPurchaseEvent = (dealId: string, car: IConfigurableCar) => {
        if (lastRecordedPurchaseId === dealId) {
            return;
        }

        pushToDataLayer({
            event: 'purchase',
            eventCategory: GTM_EVENT_CATEGORY,
            eventAction: 'Purchase',
            ecommerce: {
                purchase: {
                    actionField: {
                        id: dealId,
                        revenue: getVehiclePrice(car.prices),
                    },
                    products: [mapProductData(car, `${getSiteTarget()} new car`)],
                },
            },
        });

        setLastRecordedPurchaseId(dealId);
    };

    const pushProductImpressions = (
        page: 'home' | 'trim' | null,
        cars: Array<IConfigurableCar | IOfferItem | IVehicleTransformed>
    ) => {
        const list = () => {
            switch (true) {
                case page === 'home': {
                    return 'search results page';
                }
                case page === 'trim': {
                    return 'finition';
                }
                default: {
                    return 'stock';
                }
            }
        };

        const record: GtmProductImpression = {
            event: 'impression',
            eventCategory: GTM_EVENT_CATEGORY,
            eventAction: 'Product Impression',
            ecommerce: {
                currencyCode: t('currency'),
                impressions: cars.map((car, index) => ({
                    name: `${car.model.title} ${car.bodyStyle.title}`,
                    id: car.lcdv16 ? car.lcdv16 : extractVehicleData(car).vehicleModelBodystyle || car.bodyStyle.id,
                    price: getVehiclePrice(car.prices),
                    brand: GTM_BRAND,
                    variant: getVehicleVariant(car),
                    list: list(),
                    position: index + 1,
                    category: `${getSiteTarget()} new car`,
                    quantity: 1,
                })),
            },
        };

        pushToDataLayer(record);
    };

    const pushProductClick = (
        page: 'home' | 'trim' | 'stockTrim',
        car: IConfigurableCar | IOfferItem | IVehicleTransformed
    ) => {
        const list = (): 'search results page' | 'finition' | 'stock' => {
            switch (true) {
                case page === 'trim':
                    return 'finition';
                case page === 'stockTrim':
                    return 'stock';
                default:
                    return 'search results page';
            }
        };

        const record: GtmProductClick = {
            event: 'productClick',
            eventCategory: GTM_EVENT_CATEGORY,
            eventAction: 'Product Click',
            ecommerce: {
                click: {
                    actionField: { list: list() },
                    products: [
                        {
                            name: `${car?.model?.title} ${car?.bodyStyle?.title}`,
                            id: extractVehicleData(car)?.vehicleModelBodystyle || car?.bodyStyle?.id,
                            price: getVehiclePrice(car?.prices),
                            brand: GTM_BRAND,
                            variant: getVehicleVariant(car),
                            category: `${getSiteTarget()} new car`,
                            quantity: 1,
                        },
                    ],
                },
            },
        };

        pushToDataLayer(record);
    };

    const pushProductDetailImpression = (
        page: 'configurator' | 'basket' | null,
        car: IConfigurableCar,
        isStock: boolean
    ) => {
        const actionField = () => {
            switch (true) {
                case isStock: {
                    return 'summary';
                }
                case page === 'configurator': {
                    return 'search result page';
                }
                default: {
                    return '';
                }
            }
        };

        const record: GtmProductDetailImpression = {
            event: 'detail',
            eventCategory: GTM_EVENT_CATEGORY,
            eventAction: 'Product Detail',
            ecommerce: {
                detail: {
                    actionField: {
                        // This value really doesn't make any sense but it's correct according to latest feedback
                        list: actionField(),
                    },
                    products: [
                        {
                            name: `${car?.model?.title} ${car?.bodyStyle?.title}`,
                            id: car?.lcdv16
                                ? car.lcdv16
                                : extractVehicleData(car)?.vehicleModelBodystyle || car?.bodyStyle.id,
                            price: getVehiclePrice(car?.prices),
                            brand: GTM_BRAND,
                            variant: getVehicleVariant(car),
                            category: `${getSiteTarget()} new car`,
                            quantity: 1,
                        },
                    ],
                },
            },
        };

        pushToDataLayer(record);
    };

    const pushAddToCart = (car: IConfigurableCar) => {
        const record: GtmAddToCart = {
            event: 'addToCart',
            eventCategory: GTM_EVENT_CATEGORY,
            eventAction: 'Add to Cart',
            ecommerce: {
                currencyCode: t('currency'),
                products: [
                    {
                        name: `${car?.model?.title} ${car?.bodyStyle?.title}`,
                        id: car?.lcdv16
                            ? car.lcdv16
                            : extractVehicleData(car)?.vehicleModelBodystyle || car?.bodyStyle?.id,
                        price: getVehiclePrice(car?.prices),
                        brand: GTM_BRAND,
                        variant: getVehicleVariant(car),
                        category: `${getSiteTarget()} new car`,
                        quantity: 1,
                    },
                ],
            },
        };

        pushToDataLayer(record);
    };

    const pushUserAction = ({ action, label, value, eventName }: GtmUserActionData & { eventName?: string }) => {
        const record = {
            eventCategory: getUserActionCategory(),
            eventAction: action,
            eventLabel: label,
            ...(eventName && { event: eventName }),
            ...(value && { eventValue: value }),
        };

        pushToDataLayer(record);
    };

    const MIN = 'min';
    const MAX = 'max';

    const PushUserAction = (action: GTMFiltersActions, label: string, value?: string) => {
        pushUserAction({
            action,
            label,
            ...(value && { value }),
        });
    };

    const PushPriceAction = (oldPrices: IPriceRange, value: string | boolean | IPriceRange) => {
        const { min: oldMin, max: oldMax } = oldPrices;
        const { min: newMin, max: newMax } = value as IPriceRange;

        oldMin !== newMin && PushUserAction(GTMFiltersActions.FILTER_PRICE, MIN, String(newMin));
        oldMax !== newMax && PushUserAction(GTMFiltersActions.FILTER_PRICE, MAX, String(newMax));
    };

    const PushCarPropertyAction = (name: string, value: boolean, property: string, action: GTMFiltersActions) => {
        const type = name.replace(`${property}.`, '');
        const label = `${value ? 'Apply' : 'Remove'}::${type}`;

        PushUserAction(action, label, undefined);
    };

    const pushShareConfigurationSuccess = (page: 'basket', eventLabel: string, eventName: string = 'uaevent') => {
        const record: GtmShareConfigurationSuccessAction = {
            event: eventName,
            eventCategory: 'Content',
            eventAction: 'Redirection::Internal',
            eventLabel,
            ecommerce: {},
        };

        pushToDataLayer(record);
    };

    const pushGTMAction = (name: string, value: string | boolean | IPriceRange | StockType, oldPrices: IPriceRange) => {
        if (name === PRICES) {
            PushPriceAction(oldPrices, value);
        }

        if (name.includes(FUEL)) {
            PushCarPropertyAction(name, value as boolean, 'fuel', GTMFiltersActions.FILTER_ENERGY);
        }

        if (name.includes(GEARBOX)) {
            PushCarPropertyAction(name, value as boolean, 'fuel', GTMFiltersActions.FILTER_GEARBOX);
        }
    };

    return {
        getBodyStyleLabel,
        getVehicleBodyStyleLabel,
        pushHomePageLoad,
        pushSummaryPage,
        pushDealerPage,
        pushUserAction,
        pushAddToCart,
        pushBasketPageLoad,
        pushDeliveryPageLoad,
        pushMyDetailsPageLoad,
        pushConfiguratorPageLoad,
        pushConfirmationPageLoad,
        pushMySavesPageLoad,
        pushErrorPageLoad,
        pushProductDetailImpression,
        pushProductClick,
        pushProductImpressions,
        pushPurchaseEvent,
        pushCheckoutEvent,
        pushConnectionPagePageLoad,
        pushOrderReviewPageLoad,
        pushPreOrderPageLoad,
        pushTrimPageLoad,
        pushMyAccountPageLoad,
        pushGTMAction,
        pushRequestForFinancingLldPageLoad,
        pushShareConfigurationSuccess,
        pushMyOrdersPageLoad,
        pushToDataLayer,
        extractVehicleData,
        getVehiclePrice,
        getVehicleVariant,
        mapCarData,
        pageGTMTitle,
        isTrimPage,
        isBasketPage,
        isConfigPage,
        isSummaryPage,
    };
};
