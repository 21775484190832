import styled from 'styled-components';
import { InputRadioStyled } from '../InputRadioStyled';

export const InputRadioStyledDS = styled(InputRadioStyled)`
    [type='radio']:checked + label,
    [type='radio']:not(:checked) + label {
        font-size: ${({ theme }) => theme.fontSizes.h5};

        .description {
            font-size: ${({ theme }) => theme.fontSizes.h6};
        }
    }
`;
