import styled from 'styled-components';
import { rem } from 'polished';
import { ModalFeatureDemandTemplate } from './ModalFeatureDemandTemplate';

export const ModalFeatureDemandStyled = styled(ModalFeatureDemandTemplate)`
    .title {
        margin-top: ${rem(40)};
        padding-bottom: ${rem(20)};
        font-size: ${rem(23)};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
    }

    .sections {
        &_section {
            padding: ${rem(20)} 0;

            &__title {
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                width: 100%;

                .isClose {
                    svg {
                        width: ${rem(20)};
                        height: ${rem(25)};

                        path {
                            fill: ${({ theme }) => theme.colors.black};
                        }
                    }
                }

                .isOpen {
                    svg {
                        width: ${rem(20)};
                        height: ${rem(25)};

                        path {
                            stroke: ${({ theme }) => theme.colors.black};
                            stroke-width: 2;
                        }
                    }
                }
            }

            &__description {
                padding-top: ${rem(10)};
                margin-bottom: 0;
            }

            &__video {
                width: 100%;
                margin-top: ${rem(30)};
            }
        }
    }
`;
