import React, { useState } from 'react';
import useTranslations from '@hooks/useTranslations';
import { IModalFeatureDemand } from './index';
import Collapsible from 'react-collapsible';
import Icon, { Icons } from '@components/Icon';
import { ITrimAttribute } from '../../../interfaces/Car';
import { FEATURE_DEMAND_TYPE } from '../../../constants/main';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { AccessibleButton } from '@components/Button/AccessibleButton';

export const ModalFeatureDemandTemplate = ({ className }: IModalFeatureDemand) => {
    const { t } = useTranslations();

    const { carDetails } = useCarDetailsDuck();

    const [openSectionIds, setOpenSectionIds] = useState([]);

    const featuresDemandDescriptionData = carDetails?.trimAttributes?.find(
        (attr: ITrimAttribute) => attr.type === FEATURE_DEMAND_TYPE && attr.attributeCode === 'details'
    );

    const featuresDemandSectionsData = carDetails?.trimAttributes?.filter(
        (attr: ITrimAttribute) => attr.type === FEATURE_DEMAND_TYPE && attr.attributeCode.includes('details-features')
    );

    return (
        <div className={className}>
            <p
                className="description"
                dangerouslySetInnerHTML={{ __html: featuresDemandDescriptionData?.description }}
            />
            {featuresDemandSectionsData?.length > 0 && <div className="title">{t('featureDemand.sections.title')}</div>}
            <div className="sections">
                {featuresDemandSectionsData?.map((featuresDemandSectionData: ITrimAttribute, key: number) => {
                    const video = carDetails?.trimAttributes?.find(
                        (attr: ITrimAttribute) =>
                            attr.type === FEATURE_DEMAND_TYPE &&
                            attr.attributeCode ===
                                `details-video-${featuresDemandSectionData?.attributeCode?.replace(/[^0-9]/g, '')}`
                    );

                    return (
                        <Collapsible
                            key={key}
                            className="sections_section"
                            openedClassName="sections_section"
                            trigger={
                                <AccessibleButton
                                    className="sections_section__title"
                                    onClick={() =>
                                        openSectionIds?.includes(key)
                                            ? setOpenSectionIds(openSectionIds?.filter((openId) => openId !== key))
                                            : setOpenSectionIds([...openSectionIds, key])
                                    }
                                >
                                    <span>{featuresDemandSectionData?.title}</span>{' '}
                                    <Icon
                                        className={openSectionIds?.includes(key) ? 'isOpen' : 'isClose'}
                                        name={openSectionIds?.includes(key) ? Icons.ChevronUp : Icons.ChevronDown}
                                    />
                                </AccessibleButton>
                            }
                        >
                            {video && <video className="sections_section__video" src={video?.imageURL} controls />}
                            {!video && featuresDemandSectionData?.imageURL && (
                                <img className="sections_section__video" src={featuresDemandSectionData?.imageURL} />
                            )}
                            <p className="sections_section__description">{featuresDemandSectionData?.description}</p>
                        </Collapsible>
                    );
                })}
            </div>
        </div>
    );
};
